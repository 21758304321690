import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import axios from 'axios';

import Input from './Inputs/Input';
import Textarea from './Inputs/Textarea';
import SubmitButton from './SubmitButton';

import { LangContext } from '../../context/LangContext';

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  background-color: var(--color-black);

  @media screen and (max-width: 980px) {
    width: 100%;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  padding: 3.5rem 5rem;

  @media only screen and (max-width: 1210px) {
    margin: 8rem 0;
  }

  @media ${({ theme }) => theme.mediaQueries.smaller} {
    padding: 3rem 3.5rem;
  }

  @media ${({ theme }) => theme.mediaQueries.smallest} {
    padding: 3rem 3rem;
  }

  @media only screen and (max-width: 350px) {
    margin: 5rem 0;
    padding: 3rem 2.5rem;
  }
`;

const InputWrapper = styled.div`
  margin-bottom: 4.5rem;
  width: 45rem;

  @media ${({ theme }) => theme.mediaQueries.smaller} {
    width: 35rem;
  }

  @media ${({ theme }) => theme.mediaQueries.smallest} {
    width: 32rem;
  }

  @media only screen and (max-width: 350px) {
    width: 30rem;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 0.5rem;
`;

const SuccessMessage = styled.p`
  margin-top: 2.5rem;
  font-size: 2rem;
  font-weight: 400;
  line-height: 100%;
  color: var(--color-success-green);
`;

const ErrorMessage = styled.p`
  margin-top: 2.5rem;
  font-size: 2rem;
  font-weight: 400;
  line-height: 100%;
  color: var(--color-error-red);
`;

const ContactForm = () => {
  const { lang } = useContext(LangContext);

  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  });

  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    });
    if (ok) {
      form.reset();
    }
  };

  const handleOnSubmit = e => {
    e.preventDefault();
    const form = e.target;
    setServerState({ submitting: true });
    axios({
      method: 'post',
      url: `https://formspree.io/${process.env.GATSBY_FORMSPREE_ID}`,
      data: new FormData(form),
    })
      .then(r => {
        console.log(serverState.status);
        handleServerResponse(
          true,
          <SuccessMessage>
            {lang === 'en'
              ? 'Your message has been submitted!'
              : 'Το μύνημά σας παραδόθηκε!'}
          </SuccessMessage>,
          form
        );
      })
      .catch(r => {
        handleServerResponse(
          false,
          <ErrorMessage>
            {lang === 'en'
              ? 'Failed to send message.'
              : 'Υπήρξε σφάλμα κατά την αποστολή.'}
          </ErrorMessage>,
          form
        );
      });
  };

  return (
    <FlexContainer>
      <Form name="contact" onSubmit={handleOnSubmit}>
        <InputWrapper>
          <Input
            type="text"
            name="name"
            label={lang === 'en' ? 'Full Name' : 'Ονοματεπώνυμο'}
          />
        </InputWrapper>
        <InputWrapper>
          <Input type="email" name="_replyto" label="E-mail" />
        </InputWrapper>
        <InputWrapper>
          <Input
            type="tel"
            name="Tel"
            label={lang === 'en' ? 'Phone number' : 'Αριθμός επικοινωνίας'}
          />
        </InputWrapper>
        <InputWrapper>
          <Input
            type="text"
            name="subject"
            label={lang === 'en' ? 'Subject' : 'Θέμα'}
          />
        </InputWrapper>
        <InputWrapper>
          <Textarea
            message="message"
            label={lang === 'en' ? 'Message' : 'Μήνυμα'}
          />
        </InputWrapper>
        <ButtonWrapper>
          <SubmitButton disabled={serverState.submitting}>
            {lang === 'en' ? 'Submit' : 'Υποβολή'}
          </SubmitButton>
        </ButtonWrapper>
        {serverState.status && <div>{serverState.status.msg}</div>}
      </Form>
    </FlexContainer>
  );
};

export default ContactForm;
