import React, { useContext } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPhone,
  faEnvelope,
  faMapMarked,
  faMobile,
} from '@fortawesome/free-solid-svg-icons';

import { LangContext } from '../../context/LangContext';

const FlexContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  @media screen and (max-width: 980px) {
    width: 100%;
    margin-bottom: 5rem;
  }
`;

const Title = styled.h1`
  font-size: 5rem;
  color: var(--color-black);
  text-align: center;
  line-height: 130%;

  @media screen and (max-width: 1160px) {
    font-size: 4rem;
  }

  @media screen and (max-width: 980px) {
    margin-top: 5rem;
  }

  @media screen and (max-width: 430px) {
    font-size: 3.5rem;
  }
`;

const InfoContainer = styled.div`
  margin-top: 8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: flex-start; */

  @media screen and (max-width: 980px) {
    margin-top: 3rem;
  }
`;

const InfoElementWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 3rem 0;
  font-size: 2.6rem;

  &:not(:last-child) {
    margin-bottom: 1.2rem;
  }

  @media screen and (max-width: 430px) {
    font-size: 2rem;
  }
`;

const TextWrapper = styled.div`
  color: var(--color-black);
  margin-left: 1rem;
  letter-spacing: 1px;
`;

const StyledIcon = styled(FontAwesomeIcon)`
  color: var(--color-black);
  background-color: transparent;
`;

const InfoElement = ({ icon, info }) => (
  <InfoElementWrapper>
    <StyledIcon icon={icon} />
    <TextWrapper>{info}</TextWrapper>
  </InfoElementWrapper>
);

const ContactInfo = () => {
  const { lang } = useContext(LangContext);

  return (
    <FlexContainer>
      <Title>{lang === 'el' ? 'Επικοινωνήστε μαζί μας' : 'Contact us'}</Title>
      <InfoContainer>
        <InfoElement icon={faMobile} info={'6979002174'} />
        <InfoElement icon={faPhone} info={'2656041796'} />
        <InfoElement icon={faEnvelope} info={'kypselioprotos@gmail.com'} />
        <InfoElement
          icon={faMapMarked}
          info={
            lang === 'en' ? 'Anilio, Metsovo, 44200' : 'Ανήλιο, Μέτσοβο, 44200'
          }
        />
      </InfoContainer>
    </FlexContainer>
  );
};
export default ContactInfo;
