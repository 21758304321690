import React from 'react';
import styled from 'styled-components';
import SEO from '../components/utils/seo';

import Layout from '../components/Layout/Layout';
import ContactForm from '../components/Form/ContactForm';
import ContactInfo from '../components/ContactInfo/ContactInfo';

const FlexContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 980px) {
    flex-direction: column;
    height: auto;
  }
`;

const ContactPage = () => (
  <Layout>
    <SEO title="Contact" />
    <FlexContainer>
      <ContactInfo />
      <ContactForm />
    </FlexContainer>
  </Layout>
);

export default ContactPage;
