import React from 'react';
import styled from 'styled-components';

const InputGroup = styled.div`
  position: relative;
`;

const Label = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  color: rgba(255, 255, 255, 0.5);
  font-size: 2.2rem;
  font-weight: 400;
  line-height: 6rem;
  transition: all 0.3s ease;
`;

const Bar = styled.span`
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: var(--color-white);
  width: 100%;
  height: 1px;

  &::before,
  &::after {
    content: '';
    position: absolute;
    background-color: var(--color-primary);
    width: 0;
    height: 2px;
    transition: all 0.3s ease;
  }

  &::before {
    left: 50%;
  }

  &::after {
    right: 50%;
  }
`;

const StyledInput = styled.input`
  position: relative;
  width: 100%;
  height: 5.5rem;
  background: none;
  border: 0;
  color: var(--color-white);
  font-size: 2.2rem;
  font-weight: 400;
  outline: none;
  z-index: 1;
  padding-left: 1rem;

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }

  &:focus {
    ~ ${Label} {
      color: var(--color-primary);
      transform: translate(-12%, -65%) scale(0.75);

      @media ${({ theme }) => theme.mediaQueries.smallest} {
        transform: translate(-12%, -60%) scale(0.75);
      }

      ~ ${Bar} {
        &::before,
        &::after {
          width: 50%;
        }
      }
    }
  }

  &:not(:placeholder-shown) {
    ~ ${Label} {
      color: var(--color-input-label);
      transform: translate(-12%, -65%) scale(0.75);

      @media ${({ theme }) => theme.mediaQueries.smallest} {
        transform: translate(-12%, -60%) scale(0.75);
      }
    }

    &:invalid {
      ~ ${Label} {
        color: var(--color-error-red);
      }

      ~ ${Bar} {
        &::before,
        &::after {
          background-color: var(--color-error-red);
        }
      }
    }

    @media ${({ theme }) => theme.mediaQueries.smaller} {
      height: 5rem;
      font-size: 2rem;
    }

    @media ${({ theme }) => theme.mediaQueries.smallest} {
      height: 4rem;
      font-size: 1.8rem;
    }
  }

  :-webkit-autofill,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    box-shadow: 0 0 0 3rem #ffffff inset !important;

    ~ ${Label} {
      z-index: 10;
    }

    ~ ${Bar} {
      z-index: 10;
    }

    &:invalid {
      ~ ${Label} {
        color: var(--color-primary);
      }

      ~ ${Bar} {
        &::before,
        &::after {
          background-color: var(--color-primary);
        }
      }
    }
  }
`;

const Input = ({ type, name, label }) => (
  <InputGroup>
    <StyledInput
      type={type}
      name={name}
      placeholder="  "
      required
      autocomplete="on"
    />
    <Label>{label}</Label>
    <Bar></Bar>
  </InputGroup>
);

export default Input;
