import React from 'react';
import styled from 'styled-components';

//rgba(0, 0, 0, 0.3)
const Button = styled.button`
  text-align: center;
  text-decoration: none;
  font-family: inherit;
  font-size: 2.2rem;
  font-weight: 400;
  padding: 1.8rem 2rem;
  border: none;
  background-color: #1a1a1a;
  border-radius: 0.5rem;
  color: #ffffff;
  box-shadow: 0 0.5rem 0.8rem 0 rgba(0, 0, 0, 0.3);
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 0.6rem 1rem 0 rgba(59, 50, 12, 0.3);
    background: var(--color-primary);
    color: var(--color-black);
  }

  &:active {
    transform: translateY(-1px);
  }

  @media ${({ theme }) => theme.mediaQueries.smaller} {
    font-size: 2rem;
  }

  @media only screen and (max-width: 350px) {
    font-size: 1.8rem;
  }

  @media only screen and (max-width: 320px) {
    padding: 1.5rem 1.8rem;
  }
`;

const SubmitButton = ({ children, disabled }) => (
  <Button type="submit" disabled={disabled}>
    {children}
  </Button>
);

export default SubmitButton;
