import React from 'react';
import styled from 'styled-components';

const InputGroup = styled.div`
  position: relative;
`;

const Label = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  color: rgba(255, 255, 255, 0.5);
  font-size: 2.2rem;
  font-weight: 400;
  line-height: 6rem;
  transition: all 0.3s ease;
`;

const Bar = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: var(--color-white);

  &::before,
  &::after {
    content: '';
    position: absolute;
    background-color: var(--color-primary);
    width: 0;
    height: 2px;
    transition: all 0.3s ease;
  }

  &::before {
    left: 50%;
  }

  &::after {
    right: 50%;
  }
`;

const StyledTextarea = styled.textarea`
  position: relative;
  width: 100%;
  height: 25rem;
  padding-top: 2rem;
  background: none;
  border: none;
  color: var(--color-white);
  font-size: 2.2rem;
  font-weight: 400;
  outline: none;
  z-index: 1;

  &:focus {
    ~ ${Label} {
      color: var(--color-primary);
      transform: translate(-12%, -50%) scale(0.75);
    }

    ~ ${Bar} {
      &::before,
      &::after {
        width: 50%;
      }
    }
  }

  &:not(:placeholder-shown) {
    ~ ${Label} {
      color: var(--color-input-label);
      transform: translate(-12%, -50%) scale(0.75);
    }

    &:invalid {
      ~ ${Label} {
        color: var(--color-error-red);
      }

      ${Bar} {
        &::before,
        &::after {
          background-color: var(--color-error-red);
        }
      }
    }

    @media ${({ theme }) => theme.mediaQueries.smaller} {
      font-size: 2rem;
    }

    @media ${({ theme }) => theme.mediaQueries.smallest} {
      font-size: 1.8rem;
    }
  }
`;

const Textarea = ({ name, label }) => (
  <InputGroup>
    <StyledTextarea name={name} placeholder="  " />
    <Label>{label}</Label>
    <Bar></Bar>
  </InputGroup>
);

export default Textarea;
